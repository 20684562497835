* {
  box-sizing: border-box;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

aside {
  border-top: 1px solid gray;
  padding-top: 20px;
}

dl {
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 2px;
  grid-template-columns: 2fr 1fr;
  max-width: 300px;
}

dt {
  text-align: right;
}

dd {
  margin: 0;
}
